import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'sphere-full-page-loading-placeholder',
    templateUrl: './full-page-loading-placeholder.component.html',
    styleUrls: ['./full-page-loading-placeholder.component.scss']
})
export class FullPageLoadingPlaceholderComponent {
    @Input()
    @HostBinding('style.backgroundColor')
    public backgroundColor: string = '#303030';
    constructor() {}
}
