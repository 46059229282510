import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { JobsService } from '@app/workflow/shared/api/workflow/generated';
import { JobInputFormService } from '@app/workflow/components/job-configuration/job-input-form/job-input-form.service';

@Component({
    template: `
        <mat-form-field [ngSwitch]="formControl.status" appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput [formControl]="formControl" />
            <mat-icon matSuffix *ngSwitchCase="'VALID'" color="primary">check_circle</mat-icon>
            <mat-spinner matSuffix *ngSwitchCase="'PENDING'" [diameter]="20" [style.margin-right.px]="10"></mat-spinner>
            <mat-icon matSuffix *ngSwitchCase="'INVALID'" color="warn">error</mat-icon>
            <mat-icon matSuffix *ngSwitchDefault>code</mat-icon>
            <mat-hint align="end"> {{ formControl.value?.length || 0 }}/{{ jobInputFormService.MAX_VALUE_LENGTH }} </mat-hint>
            <mat-error *ngIf="formControl.hasError('required')">You must provide a name.</mat-error>
            <mat-error *ngIf="formControl.hasError('minlength')"
                >Name must have at least {{ jobInputFormService.MIN_VALUE_LENGTH }} characters.</mat-error
            >
            <mat-error *ngIf="!formControl.hasError('required') && formControl.hasError('uniqueName')">{{
                formControl.getError('uniqueName')
            }}</mat-error>
            <mat-error *ngIf="!formControl.hasError('required') && formControl.hasError('invalidStartCharacter')">{{
                formControl.getError('invalidStartCharacter')
            }}</mat-error>
            <mat-error *ngIf="!formControl.hasError('required') && formControl.hasError('invalidCharacter')">
                {{ formControl.getError('invalidCharacter') }}</mat-error
            >
            <mat-hint>Unique name identifier.</mat-hint>
        </mat-form-field>
    `
})
export class InputNameComponent extends FieldType implements OnInit {
    private initialTextValue: string;

    constructor(
        private job: JobsService,
        public jobInputFormService: JobInputFormService
    ) {
        super();
    }

    ngOnInit(): void {
        this.formControl.setValidators([
            this.props.required ? Validators.required : null,
            this.jobInputFormService.isStartCharacterValidValidator(),
            this.jobInputFormService.isNameValidValidator(),
            Validators.minLength(this.jobInputFormService.MIN_VALUE_LENGTH),
            Validators.maxLength(this.jobInputFormService.MAX_VALUE_LENGTH)
        ]);
        this.formControl.setAsyncValidators(this.isNameAvailableAsyncValidator());
        this.initialTextValue = this.formControl.value.slice(); // copy string value without reference
    }

    isNameAvailableAsyncValidator =
        (): AsyncValidatorFn =>
        (control: AbstractControl): Observable<ValidationErrors | null> => {
            const name = control.value;
            return name && name !== this.initialTextValue
                ? this.job.apiWorkflowV1JobsGet(null, control.value).pipe(
                      take(1),
                      catchError(error => of(null)),
                      map(response => (response.results.length === 0 || control.pristine ? null : { uniqueName: `This name is already taken.` }))
                  )
                : of(null);
        };
}
