<div class="placeholder-breadcrumbs">
    <sphere-skeleton [height]="30" [width]="85" [style.margin-right.px]="10"></sphere-skeleton>
    <sphere-skeleton [height]="30" [width]="60" [style.margin-right.px]="10"></sphere-skeleton>
    <sphere-skeleton [height]="30" [width]="150" [style.margin-right.px]="10"></sphere-skeleton>
</div>

<sphere-skeleton [height]="75" width="35%" [minWidth]="250" [style.margin-bottom.px]="20"></sphere-skeleton>

<div [style.width.%]="80" [style.min-width.px]="800" [style.margin-top.px]="30">
    <sphere-skeleton [height]="50" [width]="200"></sphere-skeleton>
    <sphere-skeleton></sphere-skeleton>
    <sphere-skeleton></sphere-skeleton>
    <sphere-skeleton width="75%"></sphere-skeleton>
</div>

<div class="placeholder-boxes" [style.width.%]="80" [style.min-width.px]="500" [style.margin-top.px]="30">
    <ng-container *ngTemplateOutlet="imageBox"></ng-container>
    <ng-container *ngTemplateOutlet="imageBox"></ng-container>
    <ng-container *ngTemplateOutlet="imageBox"></ng-container>
</div>

<ng-template #imageBox>
    <div>
        <sphere-skeleton variant="rectangular" height="100%"></sphere-skeleton>
        <div [style.margin-bottom.px]="5"></div>
        <sphere-skeleton></sphere-skeleton>
        <sphere-skeleton width="75%"></sphere-skeleton>
    </div>
</ng-template>
