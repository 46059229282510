import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileNameGeneratorDialogComponent } from '@app/workflow/components/job-configuration/job-input-form/file-name-generator-dialog/file-name-generator-dialog.component';
import { filter } from 'rxjs/operators';

export interface FileNameGeneratorDialogData {
    fileName: string;
    taskName: string;
    taskCategory: string;
    isFileNameMatched?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class FileNameGeneratorDialogService {
    constructor(private dialog: MatDialog) {}

    public openFileNameGeneratorDialog(fileName: string, taskName: string, taskCategory: string, isFileNameMatched = true) {
        return this.dialog
            .open<FileNameGeneratorDialogComponent, FileNameGeneratorDialogData, FileNameGeneratorDialogComponent>(FileNameGeneratorDialogComponent, {
                data: {
                    fileName,
                    taskName,
                    taskCategory,
                    isFileNameMatched
                },
                minWidth: 1000,
                minHeight: 800
            })
            .afterClosed()
            .pipe(filter(r => !!r));
    }
}
