import { NgModule } from '@angular/core';
import { FullPageLoadingPlaceholderComponent } from '@shared/full-page-loading-placeholder/full-page-loading-placeholder.component';
import { CommonModule } from '@angular/common';
import { LoadingPlaceholderModule } from '@shared/loading-placeholder/loading-placeholder.module';
import { SkeletonModule } from '@shared/skeleton/skeleton.module';

@NgModule({
    imports: [CommonModule, LoadingPlaceholderModule, SkeletonModule],
    declarations: [FullPageLoadingPlaceholderComponent],
    exports: [FullPageLoadingPlaceholderComponent]
})
export class FullPageLoadingPlaceholderModule {}
